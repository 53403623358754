import { useSearchParams } from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { IoLogoUsd } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { uploadFileToS3 } from 'utils/uploadS3';
import * as Yup from 'yup';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import {
  useCreatePlanMutation,
  useFindPropertiesQuery,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'layouts/apply-form';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import SelectInput from 'common/components/select-input';
import ContactCard from 'components/shared/contact-card';

const PlanStepSelectProperty = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // check for planId
  // HANDLERS
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const createPropertyOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, name, address, ...res }) => ({
        label: (
          <div className='w-full flex gap-2 justify-between'>
            <div className='text-base font-semibold'>{name || 'Building'}</div>
            <div className='text-base font-normal'>
              {address?.streetAddress1}, {address?.city}
            </div>
          </div>
        ),
        value: id,
      })
    );
  };

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();
  const draftedPlans = useSelector(selectPlansValues);
  const [
    // eslint-disable-next-line no-unused-vars
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  const {
    data: propertiesQuery,
    isError: isFindPropertiesError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertiesQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
    // textFilter
  });

  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess]);

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (
        <Formik
          enableReinitialize
          initialValues={{
            property: savedPlansValue?.property || '',
          }}
          validationSchema={Yup.object().shape({
            property: Yup.string(),
          })}
          onSubmit={(values) => {
            let submissionValues = {
              property: values.property,
            };

            let mutation = createAPlan;
            if (planId) {
              submissionValues.planId = planId;
              mutation = updatePlan;
            }

            trackFormSubmission(submissionValues);

            mutation(submissionValues)
              .unwrap()
              .then((data) => {
                dispatch(savePlansValues(data || {}));
                onNextClick();
                trackFormSubmissionSuccess(submissionValues);
              })
              .catch((error) => {
                const errMsg = extractErrorMsg(error, 'Update Failed');
                toast.error(errMsg);
                trackFormSubmissionError(submissionValues, errMsg);
              });
          }}
        >
          {({
            handleBlur,
            handleChange,
            setFieldValue,
            values,
            isValid,
            dirty: isDirty,
            handleSubmit,
            setFieldTouched,
          }) => {
            return (
              <Form
                onSubmit={(e) => e.preventDefault()}
                className='flex flex-col w-full h-full'
              >
                <div className='flex gap-4 w-full p-10 border-b border-black mobile:border-none mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
                  <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                    <h1 className='text-3xl mobile:text-2xl font-bold'>
                      Select Property
                    </h1>
                    <p className='font-medium text-xl mobile:text-lg'>
                    Pick the property you're renting.
                    </p>
                  </div>
                  
                  {/* Buttons */}
                  <ApplyPlansButtons
                    loading={createPlanLoading || updatePlanLoading}
                    stepNumber={stepNumber}
                    isValid={isValid}
                    handleSubmit={isDirty ? handleSubmit : onNextClick}
                    moveOn={fieldsDisabled}
                    onNextClick={onNextClick}
                    disableBack
                  />
                </div>

                {/* main content */}
                <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
                  {/* container */}
                  <ApplyFormContainer>
                    {/* Select Property */}
                    <ApplyFormLayout>
                      <Text className='font-semibold col-span-2'>
                        Select Building from List
                      </Text>
                      <ApplyFormField>
                        <WrapInputLabel isRequired>Building</WrapInputLabel>
                        <SelectInput
                          options={createPropertyOptionMenu(propertiesQuery)}
                          isDisabled={
                            isEmpty(propertiesQuery) || findPropertiesLoading
                          }
                          isSearchable={false}
                          placeholder={
                            findPropertiesLoading
                              ? 'Fetching...'
                              : isEmpty(propertiesQuery)
                              ? `No available buildings`
                              : 'Select building from the list'
                          }
                          name='property'
                          value={createPropertyOptionMenu(propertiesQuery).find(
                            (selectedProperty) =>
                              selectedProperty.value === values.property
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              'property',
                              selectedOption?.value || ''
                            ).then(() => {
                              setFieldTouched('property');
                            });
                          }}
                          onBlur={handleBlur}
                        />
                        <FormError name='property' />
                      </ApplyFormField>
                    </ApplyFormLayout>

                    <Accordion 
                      // defaultIndex={!savedPlansValue.property && [0]}
                      allowToggle className='border rounded-md'
                    >
                      <AccordionItem className='border-t-0 border-b rounded-b-md'>
                        <AccordionButton className='flex w-full gap-2 justify-between py-3'>
                          <Text className='font-semibold text-base'>
                            Can't find your building in the list?
                          </Text>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel className='border-t pt-5'>
                          <Text className='text-base mb-3'>
                            If you can't find your property in the list above, please
                            provide the name and address of your building to us in an email.
                          </Text>
                          {/* Can't find property */}
                          <ContactCard small />
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </ApplyFormContainer>                

                  {/* <Tracker
                    values={values}
                    errors={errors}
                    touched={touched}
                    formName='stepThree'
                  /> */}
                </div>
              </Form>
            );
          }}
        </Formik>      
  );
};

export default PlanStepSelectProperty;
