import Button from 'common/components/button';
import { useEffect, useState } from 'react';
import {
  FaBuilding,
  FaCircleInfo,
  FaCircleQuestion,
  FaCreditCard,
  FaFileSignature,
  FaIdCard,
  FaListCheck,
  FaShield,
  FaUser,
  FaUserGraduate,
} from 'react-icons/fa6';
import { FiLogOut } from 'react-icons/fi';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProgressCard from 'components/shared/progress-card';
import CancelApplicationModal from 'components/shared/cancel-application-modal';
import ConfirmActionModal from 'components/shared/confirm-action-modal';
import LogoImage from 'common/components/logo-image';
import ApplyForPlanLayout from 'layouts/apply-for-plan';
import ModalLayout from 'layouts/modal';
import { logout } from 'redux/features/auth/authSlice';
import {
  clearPlansDraft,
  selectIsSubmitted,
  selectPlanID,
  selectPlansStep,
} from 'redux/features/plans/plansSlice';
import { FaFileAlt, FaShieldAlt } from 'react-icons/fa';
import AnchorLinkTag from 'components/shared/anchor-link-tag';

const ApplyForPlan = () => {
  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const drafIsSubmitted = useSelector(selectIsSubmitted);
  const draftPlanStep = useSelector(selectPlansStep);
  const [searchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');
  const paramsIsSubmitted = searchParams.get('isSubmitted');
  const draftPlanID = useSelector(selectPlanID);

  // STATES
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [cancelModalState, setCancelModalState] = useState(false);
  const [isLogoutModalOpen, setLogoutModalState] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!cancelModalState);
  };
  const handleLogout = () => {
    dispatch(clearPlansDraft());
    dispatch(logout());
    navigate('/login');
  };

  // SIDE EFFECTS
  useEffect(() => {
    setIsSubmitted(
      paramsIsSubmitted
        ? JSON.parse(paramsIsSubmitted)
        : Boolean(drafIsSubmitted)
    );
  }, []);
  useEffect(() => {
    setStep(+paramsStep || draftPlanStep);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <>
      <main className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6 mobile:pb-0'>

        {/* Header */}
        <div className='w-full flex justify-between items-center'>
          <LogoImage className='ml-6 mobile:ml-3' small={true} />

          {/* Buttons */}
          <div>
            <div className='w-fit flex gap-2'>
              {/* desktop: help button */}
              <div className='mobile:hidden'>
                <AnchorLinkTag
                  className='w-full'
                  hrefPath='mailto:members@releaserent.com'
                >
                  <Button theme='secondary' title='Get Help' padding='px-8'/>
                </AnchorLinkTag>
              </div>

              {/* mobile: go to dashboard */}
              <div className='hidden mobile:flex'>
                <Button
                  onClick={() => {
                    navigate('/dashboard/plans');
                    dispatch(clearPlansDraft());
                  }}
                  theme='secondary'
                  padding='px-6'
                  title={
                    isSubmitted || step === 0
                      ? 'Home'
                      : 'Save'
                  }
                />
              </div>
            </div>
            
            {/* Modal */}
            <ModalLayout
              toggleModal={toggleCancelModal}
              modalIsOpen={cancelModalState}
            >
              <CancelApplicationModal
                planId={draftPlanID}
                closeModal={toggleCancelModal}
              />
            </ModalLayout>
          </div>
        </div>

        {/* Main Content */}
        <div className='h-full min-w-screen overflow-hidden flex gap-8 mobile:flex-col mobile:gap-5 no-scrollbar'>
          
          {/* aside */}
          <aside className='h-full w-full max-w-xs relative flex flex-col gap-20 mobile:max-w-none mobile:px-2 mobile:h-fit'>
            
            {/* progress */}
            <div className='flex flex-col gap-4 w-full mobile:flex-row mobile:justify-between mobile:bg-slate-200 mobile:gap-0 mobile:rounded-full mobile:overflow-hidden' >                            
              <ProgressCard
                CardIcon={FaBuilding}
                title='Select Property'
                step={1}
                isBlurred={step < 0}
                isCompleted={step > 0}
              />
              <ProgressCard
                CardIcon={FaUser}
                title='Personal Info'
                step={2}
                isBlurred={step < 1}
                isCompleted={step > 1}
              />
              <ProgressCard
                CardIcon={FaFileAlt}
                title='Lease Details'
                step={3}
                isBlurred={step < 2}
                isCompleted={step > 2}
              />
              <ProgressCard
                CardIcon={FaShieldAlt}
                title='Choose Coverage'
                step={4}
                isBlurred={step < 3}
                isCompleted={step > 3}
              />
              <ProgressCard
                CardIcon={FaCreditCard}
                title='Set Payment'
                step={5}
                isBlurred={step < 4}
                isCompleted={step > 4}
              />
              <ProgressCard
                CardIcon={FaListCheck}
                title='Confirm Details'
                step={6}
                isBlurred={step < 5}
                isCompleted={step > 5}
              />
              <ProgressCard
                CardIcon={FaIdCard}
                title='Activate Plan'
                step={7}
                isBlurred={step < 6}
                isCompleted={step > 6}
              />
            </div>

            {/* save and close application */}
            {/* if they do, provide an option to head over to the dashboard or save for later */}
            <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
              <p
                onClick={() => {
                  navigate('/dashboard/plans');
                  dispatch(clearPlansDraft());
                }}
                className='cursor-pointer hover:bg-gray-100 text-[#191923] text-base font-medium py-4 border-t border-[#E5E7EB] px-4 flex items-center gap-3'
              >
                <FiLogOut className='text-lg text-[#6A6C70]' />
                {/* Show different text depending on whether result is really saved */}
                {step === 0 ? 'Go to dashboard' : 'Save & Continue later'}
              </p>

              {/* provide an option to cancel an application if the step is greater than '1' and the user hasn't submitted their application yet. */}
              {step === 0 || step >= 6 ? null : (
                <p
                  onClick={toggleCancelModal}
                  className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 px-4 flex items-center gap-3'
                >
                  <HiMiniInformationCircle className='text-xl text-[#6A6C70]' />
                  Cancel Application
                </p>
              )}
            </div>
          </aside>

          {/* main section */}
          <ApplyForPlanLayout
            stepNumber={step}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            setStepNumber={setStep}
          />
        </div>
      </main>

      {/* MODAL */}
      <ModalLayout modalIsOpen={isLogoutModalOpen}>
        <ConfirmActionModal
          onConfirmClick={handleLogout}
          onCancel={() => setLogoutModalState(false)}
        />
      </ModalLayout>
    </>
  );
};

export default ApplyForPlan;
