export const relationshipStatusOptions = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Married',
    value: 'married',
  },
  {
    label: 'Common law',
    value: 'commonLaw',
  },
  {
    label: 'Non-common law cohabitating relationship',
    value: 'nonCommonCohab',
  },
];
export const residencyStatusOptions = [
  {
    label: 'Citizen or Permanent Resident',
    value: 'citizenPermanent',
  },
  {
    label: 'Employer-Sponsored Work Visa (i.e. Closed)',
    value: 'employerVisa',
  },
  {
    label: 'Other Visa Work (i.e. Open)',
    value: 'otherVisa',
  },
  {
    label: 'Student Visa',
    value: 'studentVisa',
  },
  {
    label: 'Other Temporary Visa',
    value: 'otherTemporaryVisa',
  },
];
export const highestEducationLevelOptions = [
  {
    label: 'Select education level',
    value: '',
  },
  {
    label: 'High School Diploma',
    value: 'highSchoolGed',
  },
  {
    label: 'Community College or Trade School Certification',
    value: 'communityTrade',
  },
  {
    label: 'Associate Degree',
    value: 'associate',
  },
  {
    label: 'Bachelors Degree',
    value: 'bachelor',
  },
  {
    label: 'Masters',
    value: 'master',
  },
  {
    label: 'PhD',
    value: 'phd',
  },
  {
    label: 'No degree',
    value: 'none',
  },
];
export const planCurrencyOptions = [
  {
    label: 'US Dollars',
    value: 'USD',
  },
  {
    label: 'Euros',
    value: 'EUR',
  },
  {
    label: 'Canadian Dollars',
    value: 'CAD',
  },
];
