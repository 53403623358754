import { Formik } from 'formik';
import { useRef } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ClaimSubmittedSuccess from 'components/claims/claim-submitted-success';
import StartClaim from 'components/apply-for-claim/start-claim';
import StartClaimApprove from 'components/apply-for-claim/start-claim-approve';
import StartClaimConfirm from 'components/apply-for-claim/start-claim-confirm';
import StartClaimHomePurchase from 'components/apply-for-claim/start-claim-home-purchase';
import StartClaimNewJob from 'components/apply-for-claim/start-claim-newjob';
import StartClaimOther from 'components/apply-for-claim/start-claim-other';
import StartClaimSelectType from 'components/apply-for-claim/start-claim-select-type';
import Button from 'common/components/button';
import { useCreateClaimMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearClaimsDraft,
  saveDraftClaims,
  selectClaimsDraftValue,
} from 'redux/features/claims/claimsSlice';

const ApplyForClaimLayout = ({
  selectedClaimType,
  setSelectedClaimType,
  stepNumber,
  setStepNumber,
  isSubmitted,
  setIsSubmitted,
  isApprovedClaim,
  setIsApprovedClaim,
}) => {
  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const [createClaim, { data: claimData, isLoading: createClaimLoading }] =
    useCreateClaimMutation();

  // HANDLERS
  const handleSaveDraft = (draft) => {
    dispatch(
      saveDraftClaims({
        claimsDraftValue: draft,
        claimsStep: stepNumber,
        selectedClaimType: selectedClaimType,
      })
    );
  };
  const handleGoToDashboard = () => {
    navigate('/dashboard/claims');
  };

  // DATA INTIIALIZATION
  const claimThemeTexts = [
    {
      title: 'Cancellation Date',
      subtitle: 'Select your plan and the date you want to cancel.',
    },
    {
      title: 'Claim Type',
      subtitle: "Tell us the type of claim and why you're moving.",
    },
    {
      title: 'Claim Details',
      subtitle: 'Provide more details about your claim and cancellation.',
    },
    {
      title: 'Confirm Info',
      subtitle: 'Review your information before submitting the claim.',
    },
    {
      title: 'Finalize Claim',
      subtitle: 'Your claim is approved. Confirm and finalize it.',
    },
  ];
  const getClaimTypeComponents = (
    handleBlur,
    handleChange,
    isValid,
    setFieldTouched,
    setFieldValue,
    values,
    type
  ) => {
    if (type === 1) {
      return (
        <StartClaimNewJob
          key={1}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          createClaimLoading={createClaimLoading}
          isValid={isValid}
          saveDraft={handleSaveDraft}
          onBack={() => setStepNumber(2)}
          onNext={() => setStepNumber(4)}
        />
      );
    } else if (type === 2) {
      return (
        <StartClaimHomePurchase
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          createClaimLoading={createClaimLoading}
          isValid={isValid}
          saveDraft={handleSaveDraft}
          onBack={() => setStepNumber(2)}
          onNext={() => setStepNumber(4)}
          key={2}
        />
      );
    } else {
      return (
        <StartClaimOther
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          createClaimLoading={createClaimLoading}
          isValid={isValid}
          saveDraft={handleSaveDraft}
          onBack={() => setStepNumber(2)}
          onNext={() => setStepNumber(4)}
          key={3}
        />
      );
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={{
        planId: draftedClaimsValue?.planId || '',
        submissionAt: draftedClaimsValue?.subtitlemissionAt || '',
        moveOutDate: draftedClaimsValue?.moveOutDate || '',
        claimType: draftedClaimsValue?.claimType || 'newJob',
        newJob: {
          companyName: draftedClaimsValue?.newJob?.companyName || '',
          jobTitle: draftedClaimsValue?.newJob?.jobTitle || '',
          offerDate: draftedClaimsValue?.newJob?.offerDate || '',
          proposedStart: draftedClaimsValue?.newJob?.proposedStart || '',
          contactName: draftedClaimsValue?.newJob?.contactName || '',
          contactEmail: draftedClaimsValue?.newJob?.contactEmail || '',
          contactPhone: draftedClaimsValue?.newJob?.contactPhone || '',
          offerPdfUrl: draftedClaimsValue?.newJob?.offerPdfUrl || '',
        },
        purchasedHome: {
          homeAddress: {
            streetAddress1:
              draftedClaimsValue?.purchasedHome?.homeAddress?.streetAddress1 ||
              '',
            streetAddress2:
              draftedClaimsValue?.purchasedHome?.homeAddress?.streetAddress2 ||
              '',
            city: draftedClaimsValue?.purchasedHome?.homeAddress?.city || '',
            state: draftedClaimsValue?.purchasedHome?.homeAddress?.state || '',
            country:
              draftedClaimsValue?.purchasedHome?.homeAddress?.country || '',
            zipCode:
              draftedClaimsValue?.purchasedHome?.homeAddress?.zipCode || '',
          },
          offerDate: draftedClaimsValue?.purchasedHome?.offerDate || '',
          proposedStart: draftedClaimsValue?.purchasedHome?.proposedStart || '',
          offerPdfUrl: draftedClaimsValue?.purchasedHome?.offerPdfUrl || '',
        },
        otherClaim: {
          description: draftedClaimsValue?.otherClaim?.description || '',
          supportingDocUrl:
            draftedClaimsValue?.otherClaim?.supportingDocUrl || '',
        },
      }}
      validationSchema={Yup.object().shape({
        planId: Yup.string().required('Required'),
        submissionAt: Yup.string(),
        moveOutDate: Yup.string().required('Required'),
        claimType: Yup.string().required('Required'),
        ...(selectedClaimType === 1
          ? {
              newJob: Yup.object().shape({
                companyName: Yup.string().required('Required'),
                jobTitle: Yup.string().required('Required'),
                offerDate: Yup.date().required('Required'),
                proposedStart: Yup.date().required('Required'),
                contactName: Yup.string().required('Required'),
                contactEmail: Yup.string()
                  .email('Invalid email address')
                  .required('Required'),
                contactPhone: Yup.string()
                  .required('Phone number is required')
                  .test(
                    'is-valid-phone',
                    'Phone number is invalid',
                    (value) => {
                      return isValidPhoneNumber(value || '');
                    }
                  ),
                offerPdfUrl: Yup.string().required('Required'),
              }),
            }
          : selectedClaimType === 2
          ? {
              purchasedHome: Yup.object().shape({
                homeAddress: Yup.object().shape({
                  streetAddress1: Yup.string().required('Required'),
                  streetAddress2: Yup.string(),
                  city: Yup.string().required('Required'),
                  state: Yup.string().required('Required'),
                  country: Yup.string().required('Required'),
                  zipCode: Yup.string().required('Required'),
                }),
                offerDate: Yup.date().required('Required'),
                proposedStart: Yup.date().required('Required'),
                offerPdfUrl: Yup.string().required('Required'),
              }),
            }
          : selectedClaimType === 3 && {
              otherClaim: Yup.object().shape({
                description: Yup.string().required('Required'),
                supportingDocUrl: Yup.string().required('Required'),
              }),
            }),
      })}
      onSubmit={(value, { resetForm }) => {
        createClaim({
          planID: value.planId,
          moveOutDate: value.moveOutDate,
          claimType: value.claimType,
          ...(selectedClaimType === 1
            ? {
                newJob: value.newJob,
              }
            : selectedClaimType === 2
            ? {
                purchasedHome: value.purchasedHome,
              }
            : selectedClaimType === 3 && {
                otherClaim: value.otherClaim,
              }),
        })
          .unwrap()
          .then(() => {
            setIsSubmitted(true);
            toast.info('Your claim has been created!', {
              toastId: 'createClaimSuccess1',
            });
            resetForm();
            dispatch(clearClaimsDraft());
          })
          .catch((error) => {
            toast.error(error?.status || 'Failed to create claim');
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        handleSubmit,
        isValid,
        setFieldTouched,
      }) => {
        return (
          <section className='h-full w-full flex flex-col items-center border border-black divide-y divide-black rounded-lg mobile:border-none mobile:rounded-none mobile:divide-slate-200'>
            {/* title */}
            {/* check if stepNumber is above 5, if it is disable this UI */}
            <div className='flex flex-col gap-2 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:gap-1'>
              {stepNumber > 5 ? null : isSubmitted ? (
                // check if it's the submission page for step 4 (we do want to show this UI, but it's weird)
                <>
                  <h1 className='text-3xl mobile:text-2xl font-bold'>
                    Claim Submitted
                  </h1>
                  <p className='font-medium text-xl mobile:text-lg'>
                    Your claim has been received and is under review.
                  </p>
                </>
              ) : (
                <>
                  <h1 className='text-3xl mobile:text-2xl font-bold'>
                    {claimThemeTexts[stepNumber - 1]?.title || ''}
                  </h1>
                  <p className='font-medium text-xl mobile:text-lg'>
                    {claimThemeTexts[stepNumber - 1]?.subtitle || ''}
                  </p>
                </>
              )}
            </div>

            {/* children content */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2'>
              {+stepNumber === 1 ? (
                <StartClaim
                  setFieldTouched={setFieldTouched}
                  stepNumber={stepNumber}
                  handleBlur={handleBlur}
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onNext={() => {
                    setStepNumber(2);
                    handleSaveDraft(values);
                  }}
                />
              ) : +stepNumber === 2 ? (
                <StartClaimSelectType
                  stepNumber={stepNumber}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  values={values}
                  selectedClaimType={selectedClaimType}
                  setSelectedClaimType={setSelectedClaimType}
                  onNext={() => {
                    setStepNumber(3);
                    handleSaveDraft(values);
                  }}
                  onBack={() => {
                    setStepNumber(1);
                    handleSaveDraft(values);
                  }}
                />
              ) : +stepNumber === 3 ? (
                getClaimTypeComponents(
                  handleBlur,
                  handleChange,
                  isValid,
                  setFieldTouched,
                  setFieldValue,
                  values,
                  selectedClaimType
                )
              ) : +stepNumber === 4 && !isSubmitted ? (
                <StartClaimConfirm
                  isValid={isValid}
                  stepNumber={stepNumber}
                  onSubmit={handleSubmit}
                  submitClaimLoading={createClaimLoading}
                  onBack={() => setStepNumber(3)}
                  saveDraft={handleSaveDraft}
                  values={claimData?.data}
                />
              ) : +stepNumber === 4 && isSubmitted ? (
                <ClaimSubmittedSuccess
                  claimStatus={claimData?.data?.status}
                  onClick={() => {
                    dispatch(clearClaimsDraft());
                    handleGoToDashboard();
                  }}
                  values={values}
                />
              ) : +stepNumber === 5 ? (
                <StartClaimApprove
                  isApprovedClaim={isApprovedClaim}
                  setIsApprovedClaim={setIsApprovedClaim}
                />
              ) : (
                <div className='w-2/3 mx-auto h-full flex flex-col gap-8 mt-[20%]'>
                  <IoMdInformationCircle className='text-[#191923] text-[54px] mx-auto' />
                  <p className='text-3xl font-semibold text-center'>
                    {`You've completed the process of creating a claim`}
                  </p>

                  {/* button */}
                  <Button onClick={handleGoToDashboard} title='Go to Home' />
                </div>
              )}
            </div>
          </section>
        );
      }}
    </Formik>
  );
};

export default ApplyForClaimLayout;
