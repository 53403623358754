import { CoverageDetail } from "components/coverages/coverage-card"

const generalLimits = <>
  {/* Limits */}
  <CoverageDetail
    title='Coverage Limit'
    description='The maximum coverage limit is the lesser of:'
    limit={['$30,000', '6 months rent']}
  >
    The coverage limit is the maximum amount that can be paid for a covered cancellation. For example, if you have a limit of 6 months, the maximum amount that will be covered for a cancellation is 6 months of rent.
  </CoverageDetail>

  {/* Deductibles */}
  <CoverageDetail
    title='Deductible'
    description='The deductible you are responsible for is the lesser of:'
    limit={['$500', 'one months rent']}
  >
    A deductible is the amount you pay out-of-pocket for covered
    cancellations before your coverage starts to pay. For example, if you have a $500 deductible, you would pay the first $500 of covered expenses for a cancellation.
  </CoverageDetail>

  {/* Elimination Period */}
  <CoverageDetail
    title='Elimination Period'
    description='The elimination period is the greater of:'
    limit={['one month', '1/12 of the lease term']}
  >
    The elimination period is the number of days you must wait after a cancellation is submitted before benefits are paid out. For example, if you have a one month elimination period, you would wait one month after a cancellation is filed before any benefits are paid out.
  </CoverageDetail>
</>

export default generalLimits
