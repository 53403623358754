import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { objectToFormData } from 'utils/objectToFormData';
import endpoints from '../endpoints.json';
import qs from 'qs';

// VARIABLES
const CIOSUSERS_API_REDUCER_KEY = 'ciosUsersApi';
const BASE_URL = `${
  process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_DEV_BASE_URL
}`;

const prepareQuery = (slug, { limit, page, textFilter, ...args }) => {
  let query = { limit, ...args };
  if (page > 1) {
    query.offset = (page - 1) * limit;
  }

  if (textFilter) {
    query.textFilter = textFilter;
  }
  return `${slug}?${qs.stringify(query)}`;
};

// Base Query
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  timeout: 1500 * 60,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // getting access token from store
    const token = getState().auth.token;

    if (token && endpoint !== 'refresh') {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// API Slice
export const ciosUsersApi = createApi({
  reducerPath: CIOSUSERS_API_REDUCER_KEY,
  baseQuery: baseQuery,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['User Details', 'Claims', 'Plans', 'Stripe Payment', 'Properties'],
  endpoints: (builder) => ({
    // AUTHENTICATION
    login: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.login,
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.registerAsTenant,
        method: 'POST',
        body,
      }),
    }),
    resendEmailToken: builder.mutation({
      query: () => ({
        url: endpoints.authentication.resendEmail,
        method: 'POST',
      }),
    }),
    confirmEmail: builder.mutation({
      query: (emailToken) => ({
        url: `${endpoints.authentication.confirmEmail}${emailToken}`,
        method: 'POST',
      }),
      invalidatesTags: ['User Details'],
    }),
    impersonationToken: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.impersonationToken,
        method: 'POST',
        body,
      }),
    }),
    retrieveUserDetails: builder.query({
      query: () => ({
        url: endpoints.tenants.retrieveSingleTenantInfo,
      }),
      transformResponse: (response) => response?.data,
      providesTags: ['User Details'],
    }),
    updatePassword: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.updatePassword,
        body,
        method: 'PATCH',
      }),
    }),
    resetPasswordRequest: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.resetPasswordRequest,
        body,
        method: 'POST',
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, token }) => ({
        url: `${endpoints.authentication.resetPassword}${token}`,
        body: {
          password,
        },
        method: 'POST',
      }),
    }),
    retrieveSpecialUserDetails: builder.query({
      query: (token) => ({
        url: endpoints.tenants.retrieveSingleTenantInfo,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
      transformResponse: (response) => response?.data,
      providesTags: ['User Details'],
    }),
    confirmAccount: builder.mutation({
      query: (body) => ({
        url: endpoints.authentication.confirmAccount,
        body,
        method: 'POST',
      }),
    }),

    // Property
    findProperties: builder.query({
      query: ({ limit, page, ...args }) => {
        return {
          url: prepareQuery(endpoints.tenants.findProperties, {
            limit,
            page,
            ...args,
          }),
        };
      },
      providesTags: ['Properties'],
      transformResponse: (response) => response?.data || response,
    }),

    // CLAIMS
    getAllClaims: builder.query({
      query: () => endpoints.tenants.retrieveAllClaims,
      providesTags: ['Claims'],
      transformResponse: (response) => response?.data || response,
    }),
    createClaim: builder.mutation({
      query: (value) => ({
        url: endpoints.tenants.createAClaim,
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Claims', 'Plans'],
    }),
    getSingleClaim: builder.query({
      query: (id) => `${endpoints.tenants.retrieveSingleClaim}${id}`,
      providesTags: ['Claims'],
      transformResponse: (response) => response?.data,
    }),
    confirmAClaim: builder.mutation({
      query: (claimId) => ({
        url: `${endpoints.tenants.confirmClaim}${claimId}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: ['Claims'],
    }),

    // PLANS
    getAllPlans: builder.query({
      query: () => endpoints.tenants.retrieveAllPlans,
      providesTags: ['Plans'],
      transformResponse: (response) => {
        console.log('Got response?');
        return response?.data;
      },
    }),
    getSinglePlan: builder.query({
      query: (id) => `${endpoints.tenants.retrieveSpecificPlan}${id}`,
      providesTags: ['Plans'],
      transformResponse: (response) => response?.data,
    }),
    getDashboardMessages: builder.query({
      query: (id) => `${endpoints.tenants.dashboardMessages}`,
      providesTags: ['User Details'],
      transformResponse: (response) => response?.data,
    }),
    downloadPlanPdf: builder.mutation({
      // Could pass in a name for the file name if wanted
      query: (id) => ({
        url: `${endpoints.tenants.downloadPlan}${id}/pdf`,
        method: 'GET',
        responseHandler: async (resp) => {
          // Not really suppose to put binary data into redux reducers
          // Also if you convert the binary to string url, it's going to be very large and probably
          // mess up redux, so handling the download logic here

          // If you want to be able to directly view the pdf, i.e. simulate click on download and it
          // shows pdf in the page, you need to run just this:
          // window.location.assign(window.URL.createObjectURL(await resp.blob()));

          // This makes it so that the file information is downloaded, rather than navigating to the pdf
          // It must work through a link with a download attribute, otherwise won't work
          const url = window.URL.createObjectURL(await resp.blob());
          const link = document.createElement('a');
          link.href = url;
          // file name would go here if needed
          link.setAttribute('download', 'Plan Details.pdf');
          document.body.appendChild(link);

          // Simulate user clicking link
          link.click();

          // clean up
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          return 'Downloaded';
        },
        cache: 'no-cache',
      }),
    }),
    createPlan: builder.mutation({
      query: (value) => ({
        url: endpoints.tenants.createAPlan,
        method: 'POST',
        body: value,
      }),
      invalidatesTags: ['Plans', 'Claims', 'User Details'],
      transformResponse: (response) => response?.data,
    }),
    updateSinglePlan: builder.mutation({
      query: ({ planId, ...body }) => ({
        url: `${endpoints.tenants.updateAPlan}${planId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
    }),
    getActivatePlan: builder.query({
      query: (id) => `${endpoints.tenants.retrieveSpecificPlan}${id}/submit`,
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
      transformErrorResponse: (response) => response?.data,
    }),
    activatePlan: builder.mutation({
      query: ({ planId, ...body }) => ({
        url: `${endpoints.tenants.activatePlan}${planId}/submit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
      transformErrorResponse: (response) => response?.data,
    }),
    submitAPlan: builder.mutation({
      query: ({ planId, body }) => ({
        url: `${endpoints.tenants.submitPlan}${planId}/submit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Plans'],
      transformResponse: (response) => response?.data,
      transformErrorResponse: (response) => response?.data,
    }),
    deleteAPlan: builder.mutation({
      query: (planId) => ({
        url: `${endpoints.tenants.deleteAPlan}${planId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Plans', 'Claims'],
    }),
    addDocToPlan: builder.mutation({
      query: ({ documentUrl, planID }) => ({
        url: `${endpoints.upload.addDocToPlan}${planID}/documents`,
        method: 'POST',
        body: {
          name: '',
          url: documentUrl,
        },
      }),
      invalidatesTags: ['Plans'],
    }),
    deleteDocFromPlan: builder.mutation({
      query: ({ planID, documentID }) => ({
        url: `${endpoints.upload.deleteDocFromPlan}${planID}/documents/${documentID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Plans'],
    }),

    // USER DOCUMENT VERIFICATION
    addDocToUser: builder.mutation({
      query: (documentUrl) => ({
        url: endpoints.upload.addDocToUser,
        method: 'POST',
        body: {
          name: documentUrl,
          url: documentUrl,
        },
      }),
      invalidatesTags: ['User Details'],
    }),
    deleteDocFromUser: builder.mutation({
      query: (documentID) => ({
        url: `${endpoints.upload.deleteDocFromUser}${documentID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User Details'],
    }),

    // UPLOAD DOCUMENT/SINGLE PDF
    uploadSinglePDF: builder.mutation({
      query: (value) => {
        // create formData value
        const formDataValue = objectToFormData(value);

        // return req body
        return {
          url: endpoints.upload.uploadSinglePDF,
          method: 'POST',
          body: formDataValue,
          formData: true,
        };
      },
    }),
    uploadDocument: builder.mutation({
      query: (document) => {
        // create formData value
        const formDataValue = objectToFormData(document);

        // return req body
        return {
          url: endpoints.upload.uploadDocument,
          method: 'POST',
          body: formDataValue,
          formData: true,
        };
      },
    }),
    uploadRequestUrl: builder.mutation({
      query: (body) => {
        return {
          url: endpoints.upload.uploadRequestUrl,
          method: 'POST',
          body,
        };
      },
    }),

    // STRIPE VERIFICATION SESSION
    createVerificationSession: builder.mutation({
      query: () => ({
        url: endpoints.tenants.createStripeKYCVerificationSession,
        method: 'POST',
      }),
      invalidatesTags: ['User Details'],
    }),

    // STRIPE PAYMENT
    requestSetupIntent: builder.mutation({
      query: () => ({
        url: endpoints.stripePayment.requestSetupIntent,
        method: 'POST',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
    addSetupIntent: builder.mutation({
      query: (setupIntentID) => ({
        url: `${endpoints.stripePayment.addSetupIntent}${setupIntentID}`,
        method: 'POST',
      }),
      invalidatesTags: ['Stripe Payment', 'User Details'],
    }),
    showAvailableMethods: builder.query({
      query: () => endpoints.stripePayment.showAvailablePaymentMethods,
      providesTags: ['Stripe Payment'],
    }),
    deletePaymentMethod: builder.mutation({
      query: (paymentMethodID) => ({
        url: `${endpoints.stripePayment.deleteAPaymentMethod}${paymentMethodID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
    markPaymentMethodAsDefault: builder.mutation({
      query: (paymentMethodID) => ({
        url: `${endpoints.stripePayment.markPaymentMethodAsDefault}${paymentMethodID}/set-default`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
    makePayment: builder.mutation({
      query: ({ planID, choices }) => ({
        url: `${endpoints.stripePayment.makePayment}${planID}/payment`,
        body: {
          choices,
        },
        method: 'POST',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
    makeOneTimePayment: builder.mutation({
      query: (planID) => ({
        url: `${endpoints.stripePayment.makeOneTimePayment}${planID}/pay/one-time`,
        method: 'POST',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
    makeMonthlyPayment: builder.mutation({
      query: (planID) => ({
        url: `${endpoints.stripePayment.makeMonthlyPayment}${planID}/pay/monthly`,
        method: 'POST',
      }),
      invalidatesTags: ['Stripe Payment'],
    }),
  }),
});

// exporting Query Hooks
export const {
  // AUTH
  useLoginMutation,
  useRegisterMutation,
  useResendEmailTokenMutation,
  useConfirmEmailMutation,
  useImpersonationTokenMutation,
  useLazyRetrieveUserDetailsQuery,
  useRetrieveUserDetailsQuery,
  useUpdatePasswordMutation,
  useResetPasswordRequestMutation,
  useResetPasswordMutation,
  useRetrieveSpecialUserDetailsQuery,
  useConfirmAccountMutation,

  // Properties
  useFindPropertiesQuery,

  // CLAIMS
  useGetAllClaimsQuery,
  useCreateClaimMutation,
  useGetSingleClaimQuery,
  useConfirmAClaimMutation,

  // PLANS
  useGetAllPlansQuery,
  useGetSinglePlanQuery,
  useGetDashboardMessagesQuery,
  useDownloadPlanPdfMutation,
  useCreatePlanMutation,
  useUpdateSinglePlanMutation,
  useSubmitAPlanMutation,
  useActivatePlanMutation,
  useGetActivatePlanQuery,
  useDeleteAPlanMutation,
  useAddDocToPlanMutation,
  useDeleteDocFromPlanMutation,

  // USER DOCUMENT VERIFICATION
  useAddDocToUserMutation,
  useDeleteDocFromUserMutation,

  // UPDLOAD DOCUMENTS
  useUploadSinglePDFMutation,
  useUploadDocumentMutation,
  useUploadRequestUrlMutation,

  // STRIPE VERIFICATION SESSION
  useCreateVerificationSessionMutation,

  // STRIPE PAYMENT
  useRequestSetupIntentMutation,
  useAddSetupIntentMutation,
  useShowAvailableMethodsQuery,
  useDeletePaymentMethodMutation,
  useMarkPaymentMethodAsDefaultMutation,
  useMakePaymentMutation,
  useMakeOneTimePaymentMutation,
  useMakeMonthlyPaymentMutation,
} = ciosUsersApi;
