import Button from 'common/components/button';
import { useLayoutEffect, useState } from 'react';
import { FiLogOut, FiSend } from 'react-icons/fi';
import { GoInfo } from 'react-icons/go';
import { HiMiniInformationCircle, HiOutlineCheckBadge } from 'react-icons/hi2';
import { IoEyeOutline } from 'react-icons/io5';
import { PiWarningCircle } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProgressCard from 'components/shared/progress-card';
import ConfirmActionModal from 'components/shared/confirm-action-modal';
import LogoImage from 'common/components/logo-image';
import ApplyForClaimLayout from 'layouts/apply-for-claim';
import ModalLayout from 'layouts/modal';
import {
  clearClaimsDraft,
  selectClaimType,
  selectClaimsStep,
} from 'redux/features/claims/claimsSlice';
import { FaCalendar, FaCheckToSlot, FaClipboardList, FaInfo, FaPaperPlane } from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';

const ApplyForClaim = () => {

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draftedClaimsStep = useSelector(selectClaimsStep);
  const draftedClaimsType = useSelector(selectClaimType);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');

  // STATES
  const [selectedClaimType, setSelectedClaimType] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(1);
  const [isCancelModalOpen, setCancelModalState] = useState(false);
  const [isApprovedClaim, setIsApprovedClaim] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!isCancelModalOpen);
  };
  const handleGoToDashboard = () => {
    navigate('/dashboard/claims');
  };

  // SIDE EFFECTS
  useLayoutEffect(() => {
    setStep(paramsStep || draftedClaimsStep);

    // check if step params exist, then delete it to avoid unwanted buggy behavious
    if (paramsStep) {
      searchParams.delete('step');
      setSearchParams(searchParams, {
        replace: true,
      });
    }
  }, []);
  useLayoutEffect(() => {
    setSelectedClaimType(draftedClaimsType);
  }, []);

  return (
    <>
      <main className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6 mobile:pb-0'>

        {/* Header */}
        <div className='w-full flex justify-between items-center'>
          <LogoImage className='ml-6 mobile:ml-3' small={true} />
          
          {/* Buttons */}
          <div>
            <div className='w-fit flex gap-2'>
              {/* cancel application */}
              {isSubmitted ? null : (
                <div className='w-fit mobile:hidden'>
                  <Button
                    onClick={toggleCancelModal}
                    theme='secondary'
                    title='Cancel Claim'
                    padding='px-8'
                  />
                </div>
              )}

              {/* mobile: return to dashboard button */}
              {step === 4 && isSubmitted ? null : +step === 5 ? null : (
                <div className='hidden mobile:flex'>
                  <Button
                    onClick={handleGoToDashboard}
                    theme='secondary'
                    title={
                      isSubmitted || step === 1
                        ? 'Cancel Claim'
                        : 'Save For Later'
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className='h-full min-w-screen overflow-hidden flex gap-8 mobile:flex-col mobile:overflow-auto mobile:gap-5 no-scrollbar'>

          {/* aside */}
          <aside className='h-full w-full max-w-xs relative flex flex-col gap-20 mobile:max-w-none mobile:px-2 mobile:h-fit'>

            {/* progress */}
            <div className='flex flex-col gap-4 w-full mobile:flex-row mobile:justify-between mobile:bg-slate-200 mobile:gap-0 mobile:rounded-full mobile:overflow-hidden'>              
              <ProgressCard
                zIndex='z-50'
                CardIcon={FaCalendar}
                title='Cancellation Date'
                step={1}
                isBlurred={step < 1}
                isCompleted={step > 1}
              />
              <ProgressCard
                zIndex='z-50'
                CardIcon={FaClipboardList}
                title='Claim Type'
                step={2}
                isBlurred={step < 2}
                isCompleted={step > 2}
              />
              <ProgressCard
                zIndex='z-50'
                CardIcon={FaInfoCircle}
                title='Claim Details'
                step={3}
                isBlurred={step < 3}
                isCompleted={step > 3}
              />
              <ProgressCard
                zIndex='z-50'
                CardIcon={FaPaperPlane}
                title='Submit Claim'
                isCompleted={step > 4 || isSubmitted}
                isBlurred={step < 4}
                step={4}
              />
              <ProgressCard
                zIndex='z-50'
                CardIcon={FaCheckToSlot}
                title='Review Claim'
                isCompleted={step > 5 || isApprovedClaim}
                step={5}
                isBlurred={step < 5}
              />
            </div>

            {/* save and close applicaiton */}
            <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
              {/* navigate user to dashboard */}
              <p
                onClick={handleGoToDashboard}
                className='cursor-pointer hover:bg-gray-100 text-[#191923] text-base font-medium py-4 border-t border-[#E5E7EB] px-4 flex items-center gap-3'
              >
                <FiLogOut className='text-lg text-[#6A6C70]' />
                {isSubmitted || step === 1
                  ? 'Go to Dashboard'
                  : 'Save & Continue Later'}
              </p>

              {/* cancel application */}
              {isSubmitted ? null : (
                <p
                  onClick={toggleCancelModal}
                  className='cursor-pointer hover:bg-gray-100 border-y border-[#E5E7EB] text-[#191923] text-base font-medium py-4 px-4 flex items-center gap-3'
                >
                  <HiMiniInformationCircle className='text-xl text-[#6A6C70]' />
                  Cancel Application
                </p>
              )}
            </div>
          </aside>

          {/* main section */}
          <ApplyForClaimLayout
            selectedClaimType={selectedClaimType}
            setSelectedClaimType={setSelectedClaimType}
            stepNumber={step}
            setStepNumber={setStep}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            isApprovedClaim={isApprovedClaim}
            setIsApprovedClaim={setIsApprovedClaim}
          />
        </div>
      </main>
      
      {/* Modal */}
      <ModalLayout
        toggleModal={toggleCancelModal}
        modalIsOpen={isCancelModalOpen}
      >
        <ConfirmActionModal
          onConfirmClick={() => {
            dispatch(clearClaimsDraft());
            handleGoToDashboard();
          }}
          onCancel={() => setCancelModalState(false)}
        />
      </ModalLayout>
    </>
  );
};

export default ApplyForClaim;
