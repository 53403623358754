import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, RadioGroup, Skeleton, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Button from 'common/components/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  savePlanPaymentMode,
  saveCoverageSelection,
  selectPlansValues,
  selectCoverageSelection,
} from 'redux/features/plans/plansSlice';
import { planPaymentMode, coverageTypes } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import {
  FaCheck,
  FaCircleXmark,
  FaClipboard,
  FaCopy,
  FaEnvelope,
  FaPhone,
  FaPlus,
  FaStar,
} from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import { toast } from 'react-toastify';
import PaymentPlanCard from 'components/plans/payment-plan-card';
import ContactCard from 'components/shared/contact-card';
import { DisclosureSection } from 'components/coverages/disclosure-card';
import DetailCard from 'components/coverages/detail-card';
import { CoverageCard, CoverageDetail } from 'components/coverages/coverage-card';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import generalLimits from 'components/coverages/general-limits';

const PlanStepChooseCoverage = ({ onBackClick, onNextClick, isGettingPlan, stepNumber }) => {
  // HOOKS
  const { trackPageLoad, trackCustomEvent } = useUserTracker();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // Copy to Clipboard
  const copyToClipboard = (text, name) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`Copied ${name || text} to clipboard`);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);
  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('PlanStepChooseCoverage');
  }, []);

  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const plansValue = useSelector(selectPlansValues);
  const coverageSelection = useSelector(selectCoverageSelection);
  const address = plansValue?.lease?.address || {};
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  let plan = singlePlan || plansValue;
  const planId = plansValue?.id || singlePlan?.id || 'unknown';
  const fmtter = currencyFormatter(plan?.lease?.currency);
  const oneTimePayment = fmtter.format(plan?.payment?.oneTimePayment);
  const oneTimeMonthlyAmount = fmtter.format(plan?.payment?.oneTimeMonthlyAmt);
  const oneTimeSaveAmount = fmtter.format(
    plan?.payment?.monthlyPayment - plan?.payment?.oneTimeMonthlyAmt
  );
  const monthlyAmount = fmtter.format(plan?.payment?.monthlyPayment);

  // Shorthands for coverage types
  const cancelAnytime = singlePlan?.coverage?.cancelAnytime
  const leaveAnytime = singlePlan?.coverage?.leaveAnytime

  console.log('plan', singlePlan);

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Choose Coverage
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Select the coverages and options for your plan.
          </p>
        </div>
          
        {/* Buttons */}
        <ApplyPlansButtons
          stepNumber={stepNumber}
          isValid={coverageSelection[coverageTypes.CANCEL_ANYTIME] || coverageSelection[coverageTypes.LEAVE_ANYTIME]}
          handleSubmit={onNextClick}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
        />
      </div>

      {/* main content */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
        <Flex w='100%' flexDir='column' gap={10}>
          {isGettingSinglePlan || isGettingPlan ? (
            <Flex flexDir='column' gap='20px'>
              <Skeleton rounded='10px' h='10dvh' w='100%' />
              <Flex w='100%' gap='20px'>
                <Skeleton rounded='10px' h='40dvh' w='100%' />
                <Skeleton rounded='10px' h='40dvh' w='100%' />
              </Flex>
              <Skeleton rounded='10px' h='40dvh' w='100%' />
              <Skeleton rounded='10px' h='40dvh' w='100%' />
            </Flex>
          ) : isEmpty(singlePlan) ? (
            <Flex
              border='1px solid'
              borderColor='gray.300'
              rounded='12px'
              w='100%'
              alignItems='center'
              justifyContent='center'
              h='60dvh'
            >
              <Text fontWeight={600} fontSize='20px'>
                No plan found!
              </Text>
            </Flex>
          ) : (
            <>
              {/* container */}
              <Flex className='w-full flex-col gap-12'>
                {/* plan card and info */}
                <Flex className='flex-col gap-6'>
                  {/* plan heading */}
                  <Flex className='flex-col gap-2 w-full items-left'>
                    <h2 className='text-2xl font-bold'>Review Plan Details</h2>
                    <Text>
                      Please review the plan details below to ensure they match your
                      lease agreement. If any changes are needed, click the button
                      below to go back and update your lease information.
                    </Text>
                  </Flex>

                  {/* plan info card */}
                  <Flex className='flex-col gap-4 w-full'>
                    <PlanInfoCard
                      plan={singlePlan}
                      member={userDetails}
                      selectedCancelAnytime={coverageSelection[coverageTypes.CANCEL_ANYTIME]}
                      selectedLeaveAnytime={coverageSelection[coverageTypes.LEAVE_ANYTIME]}
                    />
                    <Button
                      onClick={onBackClick}
                      theme='secondary'
                      title='Update Lease Information'
                    />
                  </Flex>
                </Flex>

                {/* plan coverages section */}
                <Flex className='flex-col gap-6'>
                  {/* coverages heading */}
                  <Flex className='flex-col gap-2 w-full items-left'>
                    <h2 className='text-2xl font-bold'>
                      Select Coverages For Your Plan
                    </h2>
                    <Text>
                      Select the Coverages you would like to include in your plan.
                    </Text>
                  </Flex>

                  {/* Cancel Anytime */}
                  <CoverageCard 
                    coverageType={coverageTypes.CANCEL_ANYTIME}
                    amount={cancelAnytime?.payment?.amount}                    
                    paymentOptions={
                      <Flex className='gap-4 mobile:flex-col'>
                        <Button
                          disabled={coverageSelection[coverageTypes.CANCEL_ANYTIME] === planPaymentMode.ONE_TIME}
                          onClick={(e) => {
                            dispatch(
                              saveCoverageSelection({
                                ...coverageSelection,
                                [coverageTypes.CANCEL_ANYTIME]: planPaymentMode.ONE_TIME,
                              })
                            )
                            console.log('coverageSelection', coverageSelection);
                          }}
                          title={coverageSelection[coverageTypes.CANCEL_ANYTIME] === planPaymentMode.ONE_TIME ? 'Cancel Anytime Added' : 'Add Cancel Anytime'}
                          theme={'primary'}
                          Icon={coverageSelection[coverageTypes.CANCEL_ANYTIME] === planPaymentMode.ONE_TIME ? FaCheck : FaPlus}
                        />
                        {coverageSelection[coverageTypes.CANCEL_ANYTIME] === planPaymentMode.ONE_TIME && (
                          <Button                        
                            onClick={(e) => {
                              dispatch(
                                saveCoverageSelection({
                                  ...coverageSelection,
                                  [coverageTypes.CANCEL_ANYTIME]: null,
                                })
                              )                         
                            }}
                            title={'Remove Cancel Anytime'}
                            theme={'secondary'}
                            Icon={FaCircleXmark}
                          />
                        )}
                      </Flex>
                    }
                  >
                    {generalLimits}
                  </CoverageCard>

                  {/* Leave Anytime */}
                  <CoverageCard 
                    coverageType={coverageTypes.LEAVE_ANYTIME}
                    amount={leaveAnytime?.payment?.onetime?.amount/12}
                    paymentOptions={
                      <Flex className='gap-8 flex-col'>
                        <div className='grid grid-cols-2 gap-4 mobile:flex mobile:flex-col'>
                          <Button
                            disabled={coverageSelection[coverageTypes.LEAVE_ANYTIME]}
                            onClick={(e) => {
                              dispatch(
                                saveCoverageSelection({
                                  ...coverageSelection,
                                  [coverageTypes.LEAVE_ANYTIME]: planPaymentMode.ONE_TIME,
                                })
                              )
                              console.log('coverageSelection', coverageSelection);
                            }}
                            title={coverageSelection[coverageTypes.LEAVE_ANYTIME] ? 'Leave Anytime Added' : 'Add Leave Anytime'}
                            theme={'primary'}
                            Icon={coverageSelection[coverageTypes.LEAVE_ANYTIME] ? FaCheck : FaPlus}
                            className={coverageSelection[coverageTypes.LEAVE_ANYTIME] ? '' : 'col-span-2'}
                          />
                          {coverageSelection[coverageTypes.LEAVE_ANYTIME] && (
                            <Button                        
                              onClick={(e) => {
                                dispatch(
                                  saveCoverageSelection({
                                    ...coverageSelection,
                                    [coverageTypes.LEAVE_ANYTIME]: null,
                                  })
                                )                         
                              }}
                              title={'Remove Cancel Anytime'}
                              theme={'secondary'}
                              Icon={FaCircleXmark}
                            />
                          )}                        
                        </div>
                                                
                        {coverageSelection[coverageTypes.LEAVE_ANYTIME] && (
                          <>
                          <Text className='font-semibold -mb-4'>
                            Select a Payment Plan
                          </Text>                        
                            <RadioGroup 
                              className='flex flex-col rounded-md border border-slate-200 divide-y divide-slate-200'
                              defaultValue={coverageSelection[coverageTypes.LEAVE_ANYTIME] || planPaymentMode.ONE_TIME}
                              onChange={(value) => {
                                dispatch(
                                  saveCoverageSelection({
                                    ...coverageSelection,
                                    [coverageTypes.LEAVE_ANYTIME]:
                                      value,                            
                                  })                       
                                );
                              }} 
                            >
                              <PaymentPlanCard
                                type={planPaymentMode.SUBSCRIPTION}
                                amount={`${(leaveAnytime?.payment?.subscription?.amount)?.toFixed(2)} ${plan?.lease?.currency}`}
                                value={planPaymentMode.SUBSCRIPTION}
                              />
                              <PaymentPlanCard
                                value={planPaymentMode.ONE_TIME}
                                amount={`${(leaveAnytime?.payment?.onetime?.amount/12)?.toFixed(2)} ${plan?.lease?.currency}`}
                                discount={leaveAnytime?.payment?.onetime?.discountPercentage}
                              />
                            </RadioGroup>
                          </>
                        )}
                      </Flex>
                    }
                  >
                    {generalLimits}
                  </CoverageCard>              
                </Flex>

                <Flex className='flex-col gap-2 w-full items-left text-left'>
                  <Button
                    onClick={onNextClick}
                    theme='primary'
                    title='Continue to Payment'
                  />
                </Flex>

                <DisclosureSection />           
              </Flex>
            </>
          )}
          {/* Buttons */}
          {/* <ApplyPlansButtons */}
          {/*   moveOn={true} */}
          {/*   disableBack={isEmpty(singlePlan)} */}
          {/*   isValid={!isEmpty(singlePlan)} */}
          {/*   onNextClick={onNextClick} */}
          {/*   stepNumber={stepNumber} */}
          {/*   onBackClick={onBackClick} */}
          {/* /> */}
        </Flex>
      </div>
    </>
  );
};

export default PlanStepChooseCoverage;
