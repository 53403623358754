import {
  Card,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
  Icon,
} from '@chakra-ui/react';
import Button from 'common/components/button';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import StatusLabel from 'components/shared/status-label';
import DownloadPdfButton from 'components/plans/download-button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { coverageTypes, currency, planPaymentMode, statusEnum, documentStatusEnum } from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { DisclosureCard } from 'components/coverages/disclosure-card';
import DetailCard from 'components/coverages/detail-card';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import { CoverageCard } from 'components/coverages/coverage-card';
import generalLimits from 'components/coverages/general-limits';
import { FaCheckCircle } from 'react-icons/fa';
import { HiCheckBadge } from 'react-icons/hi2';

const PlanStepConfirmDetails = ({
  stepNumber,
  onBackClick,
  onNextClick,
  isGettingPlan,
}) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('PlanStepConfirmDetails');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentMode = useSelector(selectPaymentMode);
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // DATA INITIALIZATION
  const hasCancelAnytime = singlePlan?.coverage?.cancelAnytime?.payment?.stripe?.type !== 'none';
  const hasLeaveAnytime = singlePlan?.coverage?.leaveAnytime?.payment?.onetime?.stripe.type !== 'none' || singlePlan?.coverage?.leaveAnytime?.payment?.subscription?.stripe.type !== 'none';

  console.log('singlePlan', singlePlan);

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Confirm Details
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Check your information while we finalize your setup.
          </p>
        </div>
          
        {/* Buttons */}
        <ApplyPlansButtons
          stepNumber={stepNumber}
          isValid={true}
          handleSubmit={() => {
            trackLink('Activate Plan');
            onNextClick();
          }}
          onNextClick={() => {
            trackLink('Activate Plan');
            onNextClick();
          }}
          onBackClick={() => {
            trackLink('Activate Plan Later');
            navigate('/dashboard/plans');
            dispatch(clearPlansDraft());
          }}
          backTitle='Back'
          nextTitle='Next'
        />
      </div>

      {/* main content */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
        <Flex w='100%' flexDir='column' gap={10}>
          {isGettingSinglePlan || isGettingPlan ? (
            <Flex flexDir='column' gap='20px'>
              <Skeleton rounded='10px' h='10dvh' w='100%' />
              <Flex w='100%' gap='20px'>
                <Skeleton rounded='10px' h='40dvh' w='100%' />
                <Skeleton rounded='10px' h='40dvh' w='100%' />
              </Flex>
              <Skeleton rounded='10px' h='40dvh' w='100%' />
              <Skeleton rounded='10px' h='40dvh' w='100%' />
            </Flex>
          ) : isEmpty(singlePlan) ? (
            <Flex
              border='1px solid'
              borderColor='gray.300'
              rounded='12px'
              w='100%'
              alignItems='center'
              justifyContent='center'
              h='60dvh'
            >
              <Text fontWeight={600} fontSize='20px'>
                No plan found!
              </Text>
            </Flex>
          ) : (
            <>
              {/* container */}
              <Flex className='w-full flex-col gap-10'>

                {/* Plan Activation Steps */}
                <Card className='flex-col gap-6 w-full p-6 rounded-lg border border-slate-300 shadow-none'>
                  
                  {/* Header */}
                  <Flex className='flex-col gap-1 w-full'>
                    <h2 className='font-bold text-2xl'>
                      {`Before Your Plan is Activated`}
                    </h2>
                    <Text className='text-md'>
                      {`Please confirm a few details to activate your plan. It'll only take you five minutes.`}
                    </Text>
                  </Flex>

                  {/* ID VERIFICATION */}                  
                  <Flex className={`flex-col gap-1 w-full ${userDetails?.idVerificationStatus !== documentStatusEnum.NONE && 'opacity-50'}`}>
                    <Text className='font-bold inline-flex items-center'>
                      Verify Your ID
                      {userDetails?.idVerificationStatus !== documentStatusEnum.NONE && <Icon as={HiCheckBadge} className='ml-2'/>}                      
                    </Text>

                    <Text className='text-base'>
                      {`To prevent fraud, we need a government-issued ID before activating your plan. Your privacy and information will be kept secure.`}
                    </Text>
                  </Flex>
                  

                  {/* Lease Verification */}
                  <Flex className={`flex-col gap-1 w-full ${singlePlan?.leaseVerification !== documentStatusEnum.NONE && 'opacity-50'}`}>
                    <Text className='font-bold inline-flex items-center'>
                      Verify Your Lease Agreement
                      {singlePlan?.leaseVerification !== documentStatusEnum.NONE && <Icon as={HiCheckBadge} className='ml-2'/>}    
                    </Text>

                    <Text className='text-base'>
                      {`We require you upload a copy of your signed lease agreement to validate your lease details and to help prevent fraud.`}
                    </Text>
                  </Flex>

                  <div className='w-full flex flex-col mt-2 gap-4 sm:flex-row'>
                    {/* Activate Plan */}
                    <Button
                      onClick={() => {
                        trackLink('Activate Plan');
                        onNextClick();
                      }}
                      title='Activate Plan'
                    />

                    {/* Activate Plan Later */}
                    <Button
                      onClick={() => {
                        trackLink('Activate Plan Later');
                        navigate('/dashboard/plans');
                        dispatch(clearPlansDraft());
                      }}
                      theme='secondary'
                      title='Activate Later'
                    />
                  </div>
                </Card>

                {/* plan card and info */}
                <Flex className='flex-col gap-6'>
                  {/* plan heading and status */}
                  <Flex className='flex-col gap-4 w-full'>
                    <Flex className='gap-4 items-center justify-between'>
                      <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                      <StatusLabel status={singlePlan?.status} />
                    </Flex>
                  </Flex>

                  {/* plan info card */}
                  <Flex className='flex-col gap-4 w-full'>
                    <PlanInfoCard
                      plan={singlePlan}
                      member={userDetails}                      
                    />
                  </Flex>

                  <PaymentSummaryCard
                    plan={singlePlan}
                    complete={true}
                  />
                </Flex>

                {/* plan coverages section */}
                <Flex className='flex-col gap-6 mt-6'>
                  {/* coverages heading */}
                  <Flex className='flex-col gap-1 w-full items-left'>
                    <h2 className='text-2xl font-bold'>Plan Coverages</h2>
                    <Text>
                      Your plan includes the following coverages, limits,
                      deductibles, and exclusions.
                    </Text>
                  </Flex>

                  {/* coverages */}
                  {hasCancelAnytime && (
                    <CoverageCard coverageType={coverageTypes.CANCEL_ANYTIME}>
                      {generalLimits}
                    </CoverageCard>
                  )}
                  {hasLeaveAnytime && (
                    <CoverageCard coverageType={coverageTypes.LEAVE_ANYTIME}>
                      {generalLimits}
                    </CoverageCard>
                  )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </div>
    </>
  );
};

export default PlanStepConfirmDetails;
