import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { Global } from "@emotion/react";

const InfoCard = ({ title, defaultIndex=[0], allowToggle=true, children, className }) => {
  return (
    <>
      <Global
        styles={`
          .chakra-collapse {
            overflow: visible !important;
          }
        `}
      />
      <Accordion 
        defaultIndex={defaultIndex}
        allowToggle={allowToggle} className={`w-full rounded-md border border-solid gap-4 border-slate-200 overflow-visible ${className}`}
      >
        <AccordionItem className='border-t-0 border-b rounded-b-md overflow-visible'>
          <AccordionButton className='flex w-full justify-between items-center bg-slate-200 text-[#191923] text-lg font-semibold px-4 py-3 rounded-t-md'>
            {title}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel 
            className='border-t p-0 divide-y divide-slate-200 overflow-visible'
          >
            {children}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>    
  );
};

const InfoGrid = ({ children, className }) => {
  return (
    <div
      className={`grid w-full grid-cols-4 mobile:grid-cols-2 gap-4 p-4 ${className}`}
    >
      {children}
    </div>
  );
};

const InfoField = ({ title, children, className }) => {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <label className="text-xs text-[#191923] tracking-wider uppercase font-semibold">
        {title}
      </label>

      <div className="text-[#191923]">{children}</div>
    </div>
  );
};

export { InfoCard, InfoGrid, InfoField };
