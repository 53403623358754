import { useSearchParams } from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { IoLogoUsd } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { uploadFileToS3 } from 'utils/uploadS3';
import * as Yup from 'yup';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import {
  useCreatePlanMutation,
  useFindPropertiesQuery,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'layouts/apply-form';
import { Text } from '@chakra-ui/react';

const PlanStepLeaseDetails = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  // check for planId
  // HANDLERS
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();
  const draftedPlans = useSelector(selectPlansValues);
  const [
    // eslint-disable-next-line no-unused-vars
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  const {
    data: propertiesQuery,
    isError: isFindPropertiesError,
    error: getPropertyError,
    isLoading: findPropertiesLoading,
  } = useFindPropertiesQuery({
    limit: 200,
    page: 1,
    sort: '-createdAt',
    // textFilter
  });

  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess]);

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        currency: 'USD',
        monthlyRent: savedPlansValue?.lease?.monthlyRent,
        lease: {
          address: {
            streetAddress1:
              savedPlansValue?.lease?.address?.streetAddress1,
            streetAddress2:
              savedPlansValue?.lease?.address?.streetAddress2,
            country: savedPlansValue?.lease?.address?.country,
            city: savedPlansValue?.lease?.address?.city,
            state: savedPlansValue?.lease?.address?.state,
            postalCode: savedPlansValue?.lease?.address?.postalCode,
          },
          signDate: savedPlansValue?.lease?.signDate?.split('T')[0],
          startDate: savedPlansValue?.lease?.startDate?.split('T')[0],
          endDate: savedPlansValue?.lease?.endDate?.split('T')[0],
          document: savedPlansValue?.lease?.document,
          landlordName: savedPlansValue?.lease?.landlordName,
        },
      }}
      validationSchema={Yup.object().shape({
        currency: Yup.string(),
        monthlyRent: Yup.number()
          .required('Required')
          .typeError('Must be a number')
          .positive('Must be a positive value'),
        lease: Yup.object().shape({
          address: Yup.object().shape({
            streetAddress1: Yup.string().required('Required'),
            streetAddress2: Yup.string(),
            country: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Required'),
            postalCode: Yup.string().required('Required'),
          }),
          signDate: Yup.date().required('Required'),
          startDate: Yup.date().required('Required'),
          endDate: Yup.date()
            .min(
              new Date(
                new Date(new Date().getTime() + 86400000).toDateString()
              ),
              `End Date can't be earlier than today`
            )
            .required('Required'),
          document: Yup.string(),
          landlordName: Yup.string().required('Required'),
        }),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          lease: {
            startDate: values.lease.startDate,
            endDate: values.lease.endDate,
            signDate: values.lease.signDate,
            currency: values.currency,
            monthlyRent: values.monthlyRent,
            address: {
              streetAddress1: values.lease.address.streetAddress1,
              streetAddress2: values.lease.address.streetAddress2,
              province: '',
              postalCode: values.lease.address.postalCode,
              city: values.lease.address.city,
              neighborhood: '',
              state: values.lease.address.state,
              zipCode: '',
              country: values.lease.address.country,
            },
            landlordName: values.lease.landlordName,
          },
          // owner: '',
        };

        let mutation = createAPlan;
        if (planId) {
          submissionValues.planId = planId;
          mutation = updatePlan;
        }

        if (values?.lease?.document) {
          submissionValues.lease.document = values?.lease?.document;
        }

        trackFormSubmission(submissionValues);

        mutation(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <div className='flex gap-4 w-full p-10 border-b border-black mobile:border-none mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
              <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                <h1 className='text-3xl mobile:text-2xl font-bold'>
                  Lease Details
                </h1>
                <p className='font-medium text-xl mobile:text-lg'>
                  Enter information about your lease and apartment.
                </p>
              </div>
                
              {/* Buttons */}
              <ApplyPlansButtons
                loading={createPlanLoading || updatePlanLoading}
                stepNumber={stepNumber}
                isValid={isValid}
                handleSubmit={isDirty ? handleSubmit : onNextClick}
                moveOn={fieldsDisabled}
                onNextClick={onNextClick}
                onBackClick={onBackClick}
              />
            </div>

            {/* main content */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>

              {/* container */}
              <ApplyFormContainer>
                {/* Address Details */}
                <ApplyFormLayout>
                  <Text className='font-semibold'>Rental Unit Address</Text>
                  {/* Street Address 1 */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Street Address 1</WrapInputLabel>
                    <AddressAutofill
                      browserAutofillEnabled={false}
                      accessToken={`${process.env.REACT_APP_MAPBOX_TOKEN}`}
                    >
                      <Input
                        disabled={fieldsDisabled}
                        autoComplete='street-address'
                        placeholderText='Start typing to search for an address'
                        value={values.lease.address.streetAddress1}
                        onChange={({ target: { value } }) => {
                          setFieldValue('lease.address.streetAddress1', value);
                        }}
                        onBlur={handleBlur}
                        name='lease.address.streetAddress1'
                      />
                    </AddressAutofill>
                    <FormError name='lease.address.streetAddress1' />
                  </ApplyFormField>

                  {/* Street Address 2 */}
                  <ApplyFormField>
                    <WrapInputLabel>Street Address 2</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      autoComplete='address-line2'
                      placeholderText='Your Address'
                      value={values.lease.address.streetAddress2}
                      onChange={({ target: { value } }) => {
                        setFieldValue('lease.address.streetAddress2', value);
                      }}
                      onBlur={handleBlur}
                      name='lease.address.streetAddress2'
                    />
                    <FormError name='lease.address.streetAddress2' />
                  </ApplyFormField>

                  {/* City */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>City</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      autoComplete='address-level2'
                      placeholderText='Your City'
                      value={values.lease.address.city}
                      onChange={({ target: { value } }) => {
                        setFieldValue('lease.address.city', value);
                      }}
                      onBlur={handleBlur}
                      name='lease.address.city'
                    />
                    <FormError name='lease.address.city' />
                  </ApplyFormField>

                  {/* State */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>State</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      autoComplete='address-level1'
                      placeholderText='Your State'
                      value={values.lease.address.state}
                      onChange={({ target: { value } }) => {
                        setFieldValue('lease.address.state', value);
                      }}
                      onBlur={handleBlur}
                      name='lease.address.state'
                    />
                    <FormError name='lease.address.state' />
                  </ApplyFormField>

                  {/* Zip Code */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Zip Code</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      autoComplete='postal-code'
                      placeholderText='Your Postal Code'
                      value={values.lease.address.postalCode}
                      onChange={({ target: { value } }) => {
                        setFieldValue('lease.address.postalCode', value);
                      }}
                      onBlur={handleBlur}
                      name='lease.address.postalCode'
                    />
                    <FormError name='lease.address.postalCode' />
                  </ApplyFormField>

                  {/* Country */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Country</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      autoComplete='country-name'
                      placeholderText='Your Country'
                      value={values.lease.address.country}
                      onChange={({ target: { value } }) => {
                        setFieldValue('lease.address.country', value);
                      }}
                      onBlur={handleBlur}
                      name='lease.address.country'
                    />
                    <FormError name='lease.address.country' />
                  </ApplyFormField>
                </ApplyFormLayout>

                {/* Lease Information */}
                <ApplyFormLayout>
                  <Text className='font-semibold'>
                    Information About Your Lease
                  </Text>

                  {/* Landlord Name */}
                  <ApplyFormField>
                    <WrapInputLabel small isRequired>
                      Name of your landlord or property manager
                    </WrapInputLabel>

                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Enter Name'
                      value={values.lease.landlordName}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('lease.landlordName');
                      }}
                      onBlur={handleBlur}
                      name='lease.landlordName'
                    />
                    <FormError name='lease.landlordName' />
                  </ApplyFormField>

                  {/* Lease Signing Date */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Lease Signing Date</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Select date'
                      type='date'
                      value={values.lease.signDate}
                      onChange={(event) => {
                        setFieldTouched('lease.signDate');
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      name='lease.signDate'
                    />
                    <FormError name='lease.signDate' />
                  </ApplyFormField>

                  {/* Monthly Rent */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired>
                      {`Monthly rent (USD)`}
                    </WrapInputLabel>

                    <Input
                      disabled={fieldsDisabled}
                      Icon={IoLogoUsd}
                      placeholderText='0.00'
                      value={values.monthlyRent}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('monthlyRent');
                      }}
                      onBlur={handleBlur}
                      name='monthlyRent'
                    />
                    <FormError name='monthlyRent' />
                  </ApplyFormField>

                  {/* Lease Start Date */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Lease Start Date</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Select date'
                      type='date'
                      value={values.lease.startDate}
                      onChange={(event) => {
                        handleChange(event);
                        setFieldTouched('lease.startDate');
                        const leaseStartDate = new Date(event.target.value);
                        const year = leaseStartDate.getFullYear();
                        const month = leaseStartDate.getMonth();
                        const day = leaseStartDate.getDate();
                        const yearAfter = new Date(year + 1, month, day);
                        setFieldValue('lease.endDate', formatDate(yearAfter));
                      }}
                      onBlur={handleBlur}
                      name='lease.startDate'
                    />
                    <FormError name='lease.startDate' />
                  </ApplyFormField>

                  {/* Lease End Date */}
                  <ApplyFormField small>
                    <WrapInputLabel isRequired>Lease End Date</WrapInputLabel>
                    <Input
                      disabled={fieldsDisabled}
                      placeholderText='Select date'
                      type='date'
                      value={values.lease.endDate}
                      onChange={(event) => {
                        setFieldTouched('lease.endDate');
                        handleChange(event);
                      }}
                      onBlur={handleBlur}
                      name='lease.endDate'
                    />
                    <FormError name='lease.endDate' />
                  </ApplyFormField>                

                  {/* upload current lease */}
                  <ApplyFormField>
                    <WrapInputLabel isRequired={false}>
                      Upload Lease Agreement
                    </WrapInputLabel>
                    <FileUploadInput
                      disableUpload={disableFileUpload || fieldsDisabled}
                      isUploadError={uploadStatus.isError}
                      isUploadLoading={uploadStatus.isLoading}
                      isUploadSuccess={
                        uploadStatus.isSuccess ||
                        !isEmpty(draftedPlans?.lease?.document)
                      }
                      handlePDFClear={() => {
                        setFieldValue('lease.document', '');
                      }}
                      handlePDFUpload={async (file) => {
                        let fileTrack = { fileName: file.name, size: file.size };
                        trackCustomEvent('fileUploadSelection', {
                          name: 'lease.document',
                          file: fileTrack,
                        });
                        let uploadUrlReq;
                        setUploadStatus({
                          isSuccess: false,
                          isLoading: true,
                          isError: false,
                        });

                        // Get the signing url for uploading to s3
                        try {
                          uploadUrlReq = await uploadRequestUrl({
                            fileName: file.name,
                            contentType: file.type,
                          }).unwrap();
                        } catch (e) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error(
                            e?.msg || e?.message || 'failed to upload document'
                          );
                          trackCustomEvent('fileUploadError', {
                            name: 'lease.document',
                            err: 'Failed to get the s3 signed url',
                            file: fileTrack,
                          });
                          return;
                        }

                        if (
                          !uploadUrlReq?.data?.uploadUrl ||
                          !uploadUrlReq?.data?.viewUrl
                        ) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error('failed to upload document');
                          trackCustomEvent('fileUploadError', {
                            name: 'lease.document',
                            s3Data: uploadUrlReq?.data,
                            err: 'The return payload from url request was wrong',
                            file: fileTrack,
                          });
                        }

                        // Upload the file to s3

                        try {
                          await uploadFileToS3({
                            s3Url: uploadUrlReq?.data?.uploadUrl,
                            file,
                          });

                          // Have to set the uploaded file url on the lease document
                          setUploadStatus({
                            isSuccess: true,
                            isError: false,
                            isLoading: false,
                          });
                          setDisableFileUpload(true);
                          setFieldValue(
                            'lease.document',
                            uploadUrlReq?.data?.viewUrl
                          );
                          toast.info(
                            uploadUrlReq?.status ||
                              'file has been uploaded successfully'
                          );
                          trackCustomEvent('fileUploadSuccess', {
                            name: 'lease.document',
                            s3Data: uploadUrlReq?.data,
                            file: fileTrack,
                          });
                        } catch (e) {
                          setUploadStatus({
                            isSuccess: false,
                            isError: true,
                            isLoading: false,
                          });
                          toast.error(
                            e?.msg || e?.message || 'failed to upload document'
                          );
                          trackCustomEvent('fileUploadError', {
                            name: 'lease.document',
                            s3Data: uploadUrlReq?.data,
                            err: 'Failed to upload to s3',
                            file: fileTrack,
                          });
                          return;
                        }
                      }}
                    />
                    <FormError name='lease.document' />
                  </ApplyFormField>
                </ApplyFormLayout>
              </ApplyFormContainer>

              {/* <Tracker
                values={values}
                errors={errors}
                touched={touched}
                formName='stepThree'
              /> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepLeaseDetails;
