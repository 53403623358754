import React from 'react';
import {
  Card,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
} from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';

function getLineItems(
  plan,
  selectedCancelAnytime,
  selectedLeaveAnytimeOnetime,
  selectedLeaveAnytimeSubscription
) {
  // Create a list of line items for one-time and monthly payments
  let onetime = {
    lineItems: [],
    total: 0,
  };
  let monthly = {
    lineItems: [],
    total: 0,
  };

  // Set up consts for coverages
  const cancelAnytime = plan?.coverage?.cancelAnytime;
  const leaveAnytime = plan?.coverage?.leaveAnytime;

  // Check if coverages are present
  const hasCancelAnytime =
    cancelAnytime?.payment?.stripe?.type !== 'none' || selectedCancelAnytime;
  const hasLeaveAnytimeOnetime =
    leaveAnytime?.payment?.onetime?.stripe?.type !== 'none' ||
    selectedLeaveAnytimeOnetime;
  const hasLeaveAnytimeSubscription =
    leaveAnytime?.payment?.subscription?.stripe?.type !== 'none' ||
    selectedLeaveAnytimeSubscription;
  const hasCoverages = hasCancelAnytime || hasLeaveAnytimeOnetime || hasLeaveAnytimeSubscription;

  // Add cancel anytime coverage to one-time line items if it's selected
  if (hasCancelAnytime) {
    onetime.lineItems.push({
      name: 'Cancel Anytime Coverage',
      description: 'One-time upfront payment',
      amount: cancelAnytime?.payment?.amount,
    });
  }

  // Add leave anytime coverage to one-time line items if it's selected
  if (hasLeaveAnytimeOnetime) {
    onetime.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'One-time upfront payment',
      amount: leaveAnytime?.payment?.onetime?.amount,
    });
  } else if (hasLeaveAnytimeSubscription) {
    onetime.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'First monthly payment',
      amount: leaveAnytime?.payment?.subscription?.amount,
    });
    monthly.lineItems.push({
      name: 'Leave Anytime Coverage',
      description: 'Monthly recurring payments',
      amount: leaveAnytime?.payment?.subscription?.amount,
    });
  }

  // Support for old plans that don't have coverages set up at all
  // This is a temporary fix until all plans have coverages set up
  // Remove this block once all plans have coverages set up
  // Old one-time payments
  if (!hasCoverages && plan?.payment?.choice === planPaymentMode.ONE_TIME) {
    onetime.lineItems.push({
      name: 'ReLease Coverage',
      description: 'One-time upfront payment',
      amount: plan?.payment?.oneTimePayment,
    });
  } else if (!hasCoverages && plan?.payment?.choice === planPaymentMode.SUBSCRIPTION) {
    // Old monthly payments
    onetime.lineItems.push({
      name: 'ReLease Coverage',
      description: 'First monthly payment',
      amount: plan?.payment?.monthlyPayment,
    });
    monthly.lineItems.push({
      name: 'ReLease Coverage',
      description: 'Monthly recurring payments',
      amount: plan?.payment?.monthlyPayment,
    });
  }

  // Calculate the total for one-time line items
  onetime.total = onetime.lineItems.reduce(
    (total, item) => total + item.amount,
    0
  );

  // Calculate the total for monthly line items
  monthly.total = monthly.lineItems.reduce(
    (total, item) => total + item.amount,
    0
  );

  console.log('onetime', onetime);
  console.log('monthly', monthly);

  return { onetime, monthly };
}

const PaymentSummary = ({ onetime, monthly, complete }) => {
  return (
    <>
      {/* Due now payment information */}
      {onetime.total > 0 && (
        <Flex
          className={`flex-col gap-1 text-base`}
        >
          {/* due now text */}
          <Text className='font-bold text-lg'>
            {complete ? 'Paid Upfront' : 'Due Today'}
          </Text>
          <Text className='mb-4'>
            {complete
              ? 'This was the amount you were charged when you completed your payment.'
              : 'This is the amount you will be charged today when you complete your payment. If your application is not approved, any payment made will be refunded to you.'}
          </Text>
          
          <Flex className={`flex-col gap-1 text-base border border-slate-300 rounded-md p-4 pb-3`}>
            {onetime.lineItems.map((item, index) => (
              <div key={index} className='grid grid-cols-[1fr_auto_60px] gap-2 w-full justify-between sm:grid-cols-[200px_1fr_auto_60px]'>
                <Text className='font-medium'>{item.name}</Text>
                <Text className='hidden sm:block'>{item.description}</Text>
                <Text>$</Text>
                <Text className='text-right'>{item.amount.toFixed(2)}</Text>
              </div>
            ))}

            {/* tax */}
            <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between pt-2 mt-2 border-t border-slate-300'>
              <Text className='font-medium'>Applicable Taxes</Text>
              <Text></Text>
              <Text>$</Text>
              <Text className='text-right'>0.00</Text>
            </div>

            {/* Total */}
            <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between font-bold'>
              <Text>Total</Text>
              <Text></Text>
              <Text>$</Text>
              <Text className='text-right'>{onetime.total.toFixed(2)}</Text>
            </div>
          </Flex>
        </Flex>
      )}

      {/* Due monthly payment  information */}
      {monthly.total > 0 && (
        <Flex
        className={`flex-col gap-1 text-base`}
      >
        {/* due now text */}
        <Text className='font-bold text-lg'>
          Due Monthly
        </Text>
        <Text className='mb-4'>
          This is the amount you will be charged monthly once your plan is active, until your coverage end date. If your application is not approved, any pending monthly payments will be cancelled.
        </Text>

        <Flex className={`flex-col gap-1 text-base border border-slate-300 rounded-md p-4 pb-3`}>
          {monthly.lineItems.map((item, index) => (
            <div key={index} className='grid grid-cols-[1fr_auto_60px] gap-2 w-full justify-between sm:grid-cols-[200px_1fr_auto_60px]'>
              <Text className='font-medium'>{item.name}</Text>
              <Text className='hidden sm:block'>{item.description}</Text>
              <Text>$</Text>
              <Text className='text-right'>{item.amount.toFixed(2)}</Text>
            </div>
          ))}

          {/* tax */}
          <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between pt-2 mt-2 border-t border-slate-300'>
            <Text className='font-medium'>Applicable Taxes</Text>
            <Text></Text>
            <Text>$</Text>
            <Text className='text-right'>0.00</Text>
          </div>

          {/* Total */}
          <div className='grid grid-cols-[200px_1fr_auto_60px] gap-2 w-full justify-between font-bold'>
            <Text>Total</Text>
            <Text></Text>
            <Text>$</Text>
            <Text className='text-right'>{monthly.total.toFixed(2)}</Text>
          </div>
        </Flex>
      </Flex>
      )}
    </>
  )
}

const PaymentSummaryCard = ({
  plan,
  selectedCancelAnytime,
  selectedLeaveAnytimeOnetime,
  selectedLeaveAnytimeSubscription,
  complete = false,
  paymentMode = true,
}) => {
  const { onetime, monthly } = getLineItems(plan, selectedCancelAnytime, selectedLeaveAnytimeOnetime, selectedLeaveAnytimeSubscription);

  if (onetime.total === 0 && monthly.total === 0) {
    return null;
  } else {
  return (
    <Card className='flex flex-col rounded-lg border border-slate-200'>
      <Accordion
        allowMultiple
        className='flex w-full flex-col  rounded-md overflow-hidden'
      >
        <AccordionItem className='border-none'>
          <div className='p-6 flex mobile:flex-col gap-6 justify-between items-center mobile:items-start'>
            <div className='flex flex-col gap-1'>
              <h2 className='capitalize text-2xl font-bold'>Payment Details</h2>
              <Text className='text-base'>
                Here’s a summary of your payments based on the coverages you selected.
              </Text>
            </div>

            {/* Quick view of monthly/onetime totals */}
            <Flex className='gap-5 divide-x divide-slate-200 items-center'>
              {onetime.total > 0 && (
                <div className='flex flex-col items-end mobile:items-start'>
                  <div className='text-2xl font-medium'>
                    ${onetime.total.toFixed(2)} {plan?.lease?.currency}
                  </div>
                  <div className='text-base font-normal'>
                    {complete ? 'Paid Upfront' : 'Due Today'}
                  </div>
                </div>
              )}
              {monthly.total > 0 && (
                <div className='flex flex-col items-end mobile:items-start pl-4'>
                  <div className='text-2xl font-medium'>
                    ${monthly.total.toFixed(2)} {plan?.lease?.currency}
                  </div>
                  <div className='text-base font-normal'>Due Monthly</div>
                </div>
              )}
            </Flex>
          </div>

          {/* Accordion to expand to show all */}
          <AccordionButton className='px-6 py-3 hover:bg-slate-100 border-t border-slate-200'>
            <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
              Show Expanded Payment Details
            </div>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel className='border-t p-6 flex flex-col gap-8'>
            {/* payment information */}
            <PaymentSummary onetime={onetime} monthly={monthly} complete={complete} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
}};

export default PaymentSummaryCard;
