/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import Button from 'common/components/button';
import LoadingSpinner from 'components/shared/loading-spinner';
import FinishSetupIntent from 'components/stripe/finish-setup-intent';
import Payment from 'components/stripe/payment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSinglePlanQuery,
  useRequestSetupIntentMutation,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  selectPlansValues,
  selectPaymentMode,
  selectCoverageSelection,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode, coverageTypes } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { SetupCard } from 'components/stripe/setup-card';
import PlanInfoCard from 'components/plans/plan-info-card';
import { FaCircleXmark, FaCopy, FaEnvelope, FaPhone } from 'react-icons/fa6';
import { FaCheckCircle } from 'react-icons/fa';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import ContactCard from 'components/shared/contact-card';
import { DisclosureCard } from 'components/coverages/disclosure-card';
import DetailCard from 'components/coverages/detail-card';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';

const PlanStepSetPayment = ({ onBackClick, onNextClick, isGettingPlan, stepNumber }) => {
  const { trackPageLoad, trackCustomEvent } = useUserTracker();

  useEffect(() => {
    trackPageLoad('PlanStepSetPayment');
  }, []);

  // STATES
  const [clientSecret, setSecret] = useState();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);

  // HOOKS
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  const [requestSetupIntent] = useRequestSetupIntentMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const setupIntent = searchParams.get('setup_intent');
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  const address = singlePlan?.lease?.address || {};
  const paymentMode = useSelector(selectPaymentMode);
  const coverageSelection = useSelector(selectCoverageSelection);

  console.log('singlePlan', singlePlan);

  // SIDE EFFECTS
  useEffect(() => {
    requestSetupIntent()
      .unwrap()
      .then((response) => {
        setSecret(response?.data?.clientSecret);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to setup intent!');
      });
  }, []);

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Set Payment
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Choose your payment schedule and add a payment method.
          </p>
        </div>
          
        {/* Buttons */}
        <ApplyPlansButtons
          loading={isGettingPlan}
          stepNumber={stepNumber}
          handleSubmit={onNextClick}          
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          disableBack={!isEmpty(setupIntent)}
          disableNext={!isPaymentSuccess}
        />
      </div>

      {/* main content */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
        <Flex w='100%' flexDir='column' gap={10}>
          {/* container */}

          {/* payment link */}
          {/* back button only shown when payment intent isn't set up */}
          <div className='flex-col w-full gap-8 flex justify-center'>
            {/* Heading */}
            <Flex className='flex-col gap-2 w-full pt-4'>
              <h2 className='text-2xl font-bold'>Complete Payment</h2>
              <Text>
                Please complete payment by credit card or bank account in order to
                complete your application. Once completed, your application will
                be reviewed. Once approved, you will be able to activate your
                plan. If your application is not approved, you will receive a
                refund for any payments made.
              </Text>
              <Text>
                Your coverage may be voided if you misrepresent or conceal any
                material information.{' '}
                <AnchorLinkTag
                  className='underline'
                  hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'
                >
                  Read the full terms and conditions.
                </AnchorLinkTag>
              </Text>
            </Flex>

            <Flex className='flex-col gap-2 w-full items-left'>
              <h2 className='text-xl font-bold'>Review Your Plan and Coverages</h2>
              <Text>
                Please review your plan and the coverages you have selected below before completing payment.
              </Text>
            </Flex>

            {/* plan info card */}
            <Flex className='flex-col gap-4 w-full'>
              <PlanInfoCard
                plan={singlePlan}
                member={userDetails}
                selectedCancelAnytime={coverageSelection[coverageTypes.CANCEL_ANYTIME]}
                selectedLeaveAnytime={coverageSelection[coverageTypes.LEAVE_ANYTIME]}
              />

              <PaymentSummaryCard
                plan={singlePlan}
                selectedCancelAnytime={coverageSelection[coverageTypes.CANCEL_ANYTIME]}
                selectedLeaveAnytimeOnetime={coverageSelection[coverageTypes.LEAVE_ANYTIME] === planPaymentMode.ONE_TIME}
                selectedLeaveAnytimeSubscription={coverageSelection[coverageTypes.LEAVE_ANYTIME] === planPaymentMode.SUBSCRIPTION}
                complete={false}
                paymentMode={paymentMode}
              />
            </Flex>             

            {/* Payment component */}
            {clientSecret &&
              (!isEmpty(setupIntent) ? (
                <FinishSetupIntent
                  removeSetupIntent={() => {
                    searchParams.delete('setup_intent');
                    setSearchParams(searchParams, { replace: true });
                  }}
                  onNextClick={onNextClick}
                  setPaymentSuccess={setPaymentSuccess}
                  setupIntentID={setupIntent}
                />
              ) : (
                <Payment
                  clientSecret={clientSecret}
                  returnUrl={`${window.location.href}`}
                />
              ))}
          </div> 

          <ContactCard />
        </Flex>
      </div>
    </>
  );
};

export default PlanStepSetPayment;
