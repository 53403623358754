import { useSearchParams } from 'react-router-dom';
import { AddressAutofill } from '@mapbox/search-js-react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { Form, Formik, move } from 'formik';
import { useEffect, useState } from 'react';
import { IoLogoUsd } from 'react-icons/io';
import { PiWarningCircleFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  savePlansValues,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { uploadFileToS3 } from 'utils/uploadS3';
import * as Yup from 'yup';
import ApplyPlansButtons from 'layouts/apply-plans-buttons';
import {
  useCreatePlanMutation,
  useFindPropertiesQuery,
  useUploadRequestUrlMutation,
  useUpdateSinglePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import { extractErrorMsg } from 'utils/errors';
import {
  ApplyFormContainer,
  ApplyFormField,
  ApplyFormLayout,
} from 'layouts/apply-form';
import { Flex, Text } from '@chakra-ui/react';
import RadioGroup from 'components/shared/boolean-radio-input';
import RadioButtons from 'components/shared/boolean-radio-input';
import SelectInput from 'common/components/select-input';
import { residencyStatusOptions } from 'utils/selectOptions';

const PlanStepPersonalInfo = ({
  onBackClick,
  stepNumber,
  onNextClick,
  clearSearchParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const planId = searchParams.get('planId');
  const isEdit = searchParams.get('isEdit');

  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  });

  // HOOKS
  const savedPlansValue = useSelector(selectPlansValues);
  const dispatch = useDispatch();
  const [
    // eslint-disable-next-line no-unused-vars
    createAPlan,
    { isLoading: createPlanLoading, isSuccess: isCreatePlanSuccess },
  ] = useCreatePlanMutation();
  const [
    // eslint-disable-next-line no-unused-vars
    updatePlan,
    { isLoading: updatePlanLoading, isSuccess: isUpdatePlanSuccess },
  ] = useUpdateSinglePlanMutation();

  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  // eslint-disable-next-line no-unused-vars
  const {
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
    trackCustomEvent,
  } = useUserTracker();

  // SIDE EFFECTS
  useEffect(() => {
    if (isCreatePlanSuccess || isUpdatePlanSuccess) {
      clearSearchParams();
    }
  }, [isCreatePlanSuccess, isUpdatePlanSuccess]);

  // Not sure why we implemented this feature, but it exists
  // const fieldsDisabled = !isEmpty(savedPlansValue) && !isEdit;
  const fieldsDisabled = false;

  return (   
    <Formik
      enableReinitialize
      initialValues={{
        jobQuitPlans: savedPlansValue?.jobQuitPlans,
        movePlans: savedPlansValue?.movePlans, 
        residencyStatus: savedPlansValue?.residencyStatus || 'citizenPermanent',
        visaExpiration: savedPlansValue?.visaExpiration?.split('T')[0] || '',
      }}
      validationSchema={Yup.object().shape({
        jobQuitPlans: Yup.boolean().required(),
        movePlans: Yup.boolean().required(),
        residencyStatus: Yup.string().required(),
        visaExpiration: Yup.date(),
      })}
      onSubmit={(values) => {
        let submissionValues = {
          jobQuitPlans: values.jobQuitPlans,
          movePlans: values.movePlans,
          residencyStatus: values.residencyStatus,
          visaExpiration: values.visaExpiration,
        };

        let mutation = createAPlan;
        if (planId) {
          submissionValues.planId = planId;
          mutation = updatePlan;
        }

        trackFormSubmission(submissionValues);

        mutation(submissionValues)
          .unwrap()
          .then((data) => {
            dispatch(savePlansValues(data || {}));
            onNextClick();
            trackFormSubmissionSuccess(submissionValues);
          })
          .catch((error) => {
            const errMsg = extractErrorMsg(error, 'Update Failed');
            toast.error(errMsg);
            trackFormSubmissionError(submissionValues, errMsg);
          });
      }}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        values,
        isValid,
        dirty: isDirty,
        handleSubmit,
        setFieldTouched,
      }) => {
        return (
          <Form
            onSubmit={(e) => e.preventDefault()}
            className='flex flex-col w-full h-full'
          >
            <div className='flex gap-4 w-full p-10 border-b border-black mobile:border-none mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
              <div className='flex flex-col gap-2 w-full mobile:gap-1'>
                <h1 className='text-3xl mobile:text-2xl font-bold'>
                  Personal Info
                </h1>
                <p className='font-medium text-xl mobile:text-lg'>
                Share some basic details about yourself.
                </p>
              </div>
              
              {/* Buttons */}
              <ApplyPlansButtons
                loading={createPlanLoading || updatePlanLoading}
                stepNumber={stepNumber}
                isValid={isValid}
                handleSubmit={isDirty ? handleSubmit : onNextClick}
                moveOn={fieldsDisabled}
                onNextClick={onNextClick}
                onBackClick={onBackClick}
              />
            </div>

            {/* main content */}
            <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
            {/* container */}
            <ApplyFormContainer>
              {/* Pre-meditated events */}
              <ApplyFormLayout>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Have you already signed or otherwise committed to an offer of employment that may require you to prematurely cancel your lease?
                  </Text>
                  <RadioButtons
                    value={values.jobQuitPlans != null ? values.jobQuitPlans.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      console.log('jobQuitPlans', booleanValue);
                      setFieldValue('jobQuitPlans', booleanValue);
                    }}
                    name="jobQuitPlans"
                  />
                  <FormError name='jobQuitPlans' />
                </Flex>
                <Flex className='gap-5 col-span-2 justify-between items-center mobile:flex-col mobile:gap-4 mobile:items-start'>
                  <Text className='font-normal'>
                    Are you currently aware of or committed to any other plans or decisions that may require you to prematurely cancel your lease?
                  </Text>
                  <RadioButtons
                    value={values.movePlans != null ? values.movePlans.toString() : ''}
                    onChange={(value) => {
                      const booleanValue = value === 'true';
                      console.log('movePlans', booleanValue);
                      setFieldValue('movePlans', booleanValue);
                    }}
                    name="movePlans"
                  />
                  <FormError name='movePlans' />
                </Flex>
              </ApplyFormLayout>

              {/* Residency Status */}
              <ApplyFormLayout>
                {/* Select Residency Status */}
                <ApplyFormField>
                  <WrapInputLabel isRequired>Residency Status</WrapInputLabel>
                  <SelectInput
                    disabled={fieldsDisabled}
                    placeholderText='Select your residency status'
                    options={residencyStatusOptions}
                    value={residencyStatusOptions.find(
                      (status) => status.value === values.residencyStatus
                    )}
                    onChange={(value) => {
                      setFieldValue('residencyStatus', value.value);
                      console.log('residencyStatus', value.value);
                    }}
                    onBlur={handleBlur}
                    name='residencyStatus'
                  />
                  <FormError name='residencyStatus' />
                </ApplyFormField>

                {/* Visa Expiration Date */}
                {values.residencyStatus != 'citizenPermanent' && (
                  <ApplyFormField>
                    <WrapInputLabel isRequired>Visa Expiration Date</WrapInputLabel>
                    <Input
                      type='date'
                      name='visaExpiration'
                      value={values.visaExpiration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <FormError name='visaExpiration' />
                  </ApplyFormField>
                )}
              </ApplyFormLayout>
            </ApplyFormContainer>            

            {/* <Tracker
              values={values}
              errors={errors}
              touched={touched}
              formName='stepThree'
            /> */}
            </div>            
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlanStepPersonalInfo;
