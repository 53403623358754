// Component for custom radio buttons based on Chakra UI for yes/no (true/false) values in forms
// Most common use case: disclosure questions for high risk applicants (e.g. are you on an expiring visa?)

import {
  Box,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react'

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const radio = getRadioProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...radio}
        className={`min-w-fit cursor-pointer px-6 py-2 text-base font-medium transition-all duration-200 ${
          props.isChecked
            ? 'bg-slate-200 font-semibold'
            : 'bg-white text-gray-800 hover:bg-gray-100'
        }`}
      >
        {props.children}
      </Box>
    </Box>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
function RadioGroup({ value, name, onChange, className }) {
  const options = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ]

  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  })

  const group = getRootProps()

  return (
    <div {...group} className={`grid grid-cols-2 min-w-fit border rounded-md overflow-hidden w-fit divide-x divide-slate-200 ${className}`}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value })
        return (
          <RadioCard key={option.value} {...radio}>
            {option.label}
          </RadioCard>
        )
      })}
    </div>
  )
}

export default RadioGroup;
